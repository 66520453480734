import React, { useState, useEffect, useRef } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomId
} from '@mui/x-data-grid-generator';

function ComponentMappingForm(props) {
  const _apiUrl = process.env.REACT_APP_API_URL;

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [pageSize, setPageSize] = React.useState(5);
  const [siteDetails, setSiteDetails] = useState([]);
  const [componentGroupNames, setComponentGroupNames] = useState([]);

  const { setAlert } = useAlert();

  const defaultRow = {
    componentGroupId: 0,
    siteName: "",
    componentGroupName: "",
    description: "",
    levelId: "",
    governmentLevelId: "",
    add3PlComment: "",
    windowSubtractDays: 0,
    windowAddDays: 0,
    componentGroupDetailsInfo: [],
    modifiedBy: "",
    modifiedOn: ""

  };
  const AddComponentflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
  const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;
  const [rows, setRows] = React.useState(SelectedValue == "" ? [] : SelectedValue.componentGroupDetailsInfo);
  const [CompValue, SelectedCompValue] = React.useState(SelectedValue);
  const [site, setSite] = React.useState(SelectedValue.siteName);
  const [detailOrderNumber, setDetailOrderNumber] = React.useState(1);

  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].username;

  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      setDetailOrderNumber(detailOrderNumber + 1);
      const id = randomId();
      const date = new Date();
      setRows((oldRows) => [...oldRows, { id, componentGroupId: 0, componentGroupDetailId: 0, analysis: '', smComponent: '', orderNumber: detailOrderNumber, customerComponent: '', governementComponent: '', decimalPlaces: 0, active: true, modifiedBy: username, modifiedDate: date, isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleChangeSite = (event) => {
    setSite(event.target.value);
    CompValue.siteName = event.target.value;
    SelectedCompValue(CompValue);
    GetComponentGroupNames();
  };
  const CloseDialoginParent = (flag) => {
    props.CloseDialog(flag);
  };

  const saveComponentValues = async () => {
    const saveBtn = document.getElementById('btnSave');
    const serviceUrl = _apiUrl + (AddComponentflag ? "AddComponentGroupInfo" : "UpdateComponentGroupInfo")

    if (CompValue.siteName == null || CompValue.siteName === undefined || CompValue.siteName === "") {
      setAlert("primary", "Validation", "Please select Site");
      return false;
    }
    if (CompValue.componentGroupName == null || CompValue.componentGroupName === undefined || CompValue.componentGroupName === "") {
      setAlert("primary", "Validation", "Please select Component Group Name");
      return false;
    }
    if (CompValue.add3PlComment == null || CompValue.add3PlComment === undefined || CompValue.add3PlComment === "") {
      setAlert("primary", "Validation", "Please enter Add 3PL Comment");
      return false;
    }
    if (CompValue.windowAddDays == null || CompValue.windowAddDays === undefined || CompValue.windowAddDays === 0) {
      setAlert("primary", "Validation", "Please enter Window Add Days");
      return false;
    }
    if (CompValue.windowSubtractDays == null || CompValue.windowSubtractDays === undefined || CompValue.windowSubtractDays === 0) {
      setAlert("primary", "Validation", "Please enter Window Subtract Days");
      return false;
    }
    CompValue.modifiedBy = username;
    saveBtn.disabled = true;
    await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(CompValue))
      .then(response => {
        if (response.ok) {
          saveBtn.disabled = false;
          return response.json();

        }
        throw new Error('Something went wrong.');
      })
      .then(response => {
        if (response.result)
          CloseDialoginParent(true);

        setAlert("success", "Success", response.message);
        saveBtn.disabled = false;
      })
      .catch(error => {
        setAlert("danger", "Error", error.message);
        console.error(error);
        saveBtn.disabled = false;
      });

  };

  const GetSiteDetails = async () => {

    await fetchDataAuthenticated(_apiUrl + "UniqueGomSiteDetails")
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then(data => {
        setSiteDetails(data);
      })
      .catch(error => {
        setAlert("danger", "Error", error.message);
        console.error(error);
      });

  };

  useEffect(() => {
    GetSiteDetails();
    if(!AddComponentflag)
    {
      GetComponentGroupNames();
    }
  }, []);

  const GetComponentGroupNames = async () => {

    await fetchDataAuthenticated(_apiUrl + "ComponentGroupNames/" + CompValue.siteName)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then(data => {
        setComponentGroupNames(data);
      })
      .catch(error => {
        setAlert("danger", "Error", error.message);
        console.error(error);
      });

  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (row) => async () => {
    setRows(rows.filter((row) => row.id !== row.id));

    // const updatedRows = rows.map((row) => (row.id == newRow.id ? updatedRow : row));
    // setRows(updatedRows);
    //CompValue.componentGroupDetailsInfo = rows;

    const ComponentMapFields = {
      ComponentGroupDetailId: row.componentGroupDetailId,
      ModifiedBy: username,
      Active: false
    };

    await fetchDataAuthenticated(_apiUrl + 'DeleteComponentGroupDetailInfo', "POST", JSON.stringify(ComponentMapFields))
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then(response => {
        setAlert("success", "Success", response.message);
      })
      .catch(error => {
        setAlert("danger", "Error", error.message);
        console.error(error);
      });

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) => (row.id == newRow.id ? updatedRow : row));
    setRows(updatedRows);
    CompValue.componentGroupDetailsInfo = updatedRows;
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleTextFieldChange = (event) => {
    CompValue[event.target.id] = event.target.value;
    SelectedCompValue(CompValue);
  };

  const [componentGroupName, setcomponentGroupName] = React.useState(SelectedValue.componentGroupName);
  const handleChangeComponentGroupName = (event) => {
    setcomponentGroupName(event.target.value);
    CompValue.componentGroupName = event.target.value;
    SelectedCompValue(CompValue);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'id', headerName: 'Id', width: 0, editable: true },
    { field: 'componentGroupId', headerName: 'Component Group Id', width: 0, editable: true },
    { field: 'componentGroupDetailId', headerName: 'Component Group Detail Id', width: 0, editable: true },
    {
      field: 'analysis',
      headerName: 'Analysis',
      width: 130,
      editable: true,
    },
    {
      field: 'smComponent',
      headerName: 'SM Component',
      width: 350,
      editable: true,
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      type: 'number',
      width: 100,
      align:'left',
      editable: true
    },
    {
      field: 'customerComponent',
      headerName: 'Customer Component',
      width: 200,
      editable: true
    },
    {
      field: 'governementComponent',
      headerName: 'Governement Component',
      width: 200,
      editable: true
    },
    {
      field: 'decimalPlaces',
      headerName: 'Decimal Places',
      type: 'number',
      width: 100,
      align:'left',
      editable: true
    },
    {
      field: 'modifiedBy',
      headerName: 'Modified By',
      width: 200
    },
    {
      field: 'modifiedDate',
      headerName: 'Modified Date',
      width: 150
    }
  ];

  return (
    <DialogContent>
      <div className='container-fluid csDialogbottom'>
        <div className='container-fluid csDialogbottom'>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="lblsiteId" className='required'>Site</InputLabel>
                  <Select
                    labelId="lblSiteId"
                    id="isiteId"
                    value={site}
                    label="Site *"
                    onChange={handleChangeSite}
                  >
                    <MenuItem value="">Select Site</MenuItem>
                    {siteDetails.map((sitedt, index) => {
                      return (
                        <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                      );
                    })
                    }
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className='col-md-4'>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="lblComponentGroupName" className='required'>Component Group Name</InputLabel>
                  <Select
                    labelId="lblComponentGroupName"
                    id="iComponentGroupName"
                    value={componentGroupName}
                    label="Component Group Name *"
                    onChange={handleChangeComponentGroupName}
                  >
                    <MenuItem value="">Select Component Group</MenuItem>
                    {componentGroupNames.map((compdt, index) => {
                      return (
                        <MenuItem value={compdt.componentGroupName} key={"lblComponentGroupName" + compdt.componentGroupLookupId + index}>{compdt.componentGroupName}</MenuItem>
                      );
                    })
                    }
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="levelId"
                  label="Level Id"
                  defaultValue={CompValue.levelId}
                  onChange={handleTextFieldChange}
                  inputProps={{ maxLength: 20 }}

                />
              </Box>
            </div>

          </div>
          <div className='clearfix'></div>
          <div className='row mt-2'>


            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="governmentLevelId"
                  label="Government Level Id"
                  defaultValue={CompValue.governmentLevelId}
                  onChange={handleTextFieldChange}
                  inputProps={{ maxLength: 20 }}
                />
              </Box>
            </div>

            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField required
                  id="add3PlComment"
                  label="Add 3PL Comment"
                  defaultValue={CompValue.add3PlComment}
                  onChange={handleTextFieldChange}
                />
              </Box>
            </div>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField required
                  id="windowAddDays"
                  label="Window Add Days"
                  defaultValue={CompValue.windowAddDays}
                  onChange={handleTextFieldChange}
                  type="number"
                />
              </Box>
            </div>
          </div>
          <div className='clearfix'></div>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField required
                  id="windowSubtractDays"
                  label="Window Subtract Days"
                  defaultValue={CompValue.windowSubtractDays}
                  onChange={handleTextFieldChange}
                  type="number"
                />
              </Box>
            </div>

            <div className='col-md-8'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& > :not(style)': { m: 0 },
                }}
              >
                <TextField
                  id="description"
                  label="Description"
                  defaultValue={CompValue.description}
                  onChange={handleTextFieldChange}
                />
              </Box>
            </div>
          </div>

        </div>

        <div className='clearfix'></div>
        <div className='col-md-12'>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              initialState={{
                ...rows.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              getRowId={(row) => row.id}
              columnVisibilityModel={{ componentGroupDetailId: false, id: false, componentGroupId: false }}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </div>
        <div className='clearfix'></div>
        <div className='row'>
          <div className='col-md-9'></div>
          <div className='col-md-3 row'>
            <div className='col-md-6'>
              <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
            </div>
            <div className='col-md-6'>
              <button type="button" id="btnSave" className="btn btn-outline-primary" onClick={saveComponentValues}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </DialogContent>
  );
}
export default ComponentMappingForm;