import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState} from 'react';
import { client as publicClientApplication } from './utilities/AuthUtils';
import { usePrivileges } from './hooks/usePrivileges';

import "./Components/NavBar.css";
import { AlertProvider } from './Components/Alerts/AlertContext';
import NotificationAlert from './Components/Alerts/NotificationAlert';

import NavBar from "./Components/NavBar";
import Footer from './Components/Footer';

// Pages
import Home from './Components/Pages/Home';
import LogDetails from './Components/Pages/Logs/LogDetails';
import ComponentGroupLookupGrid from './Components/Pages/ComponentGroupLookup/ComponentGroupLookupGrid';
import SiteGrid from './Components/Pages/Site/SiteGrid';
import ILRComponentMappingGrid from './Components/Pages/ILRComponentMapping/ILRComponentMappingGrid';
import ILRSiteGrid from './Components/Pages/ILRSite/ILRSiteGrid';
import ComponentMappingGrid from './Components/Pages/ComponentMapping/ComponentMappingGrid';
import CofAConfiguration from "./Components/Pages/CofAConfiguration/CofAConfiguration"
import CofQConfiguration from "./Components/Pages/CofQConfiguration/CofQConfiguration"
import ILRInspectionLotRequestGrid from './Components/Pages/ILRInspectionLotRequest/ILRInspectionLotRequestGrid';
import ILRMaterialMapGrid from './Components/Pages/ILRMaterialMap/ILRMaterialMapGrid';
import DocLand from "./Components/Pages/Documentation/DocLand"
import GEMSLanding from "./Components/Pages/Documentation/GEMS/GEMSLanding"
import SampleFind from "./Components/Pages/Documentation/GEMS/SampleFind"
import ILRMaterialMapDoc from "./Components/Pages/Documentation/GEMS/ILRMaterialMapDoc"
import ILRComponentMappingDoc from "./Components/Pages/Documentation/GEMS/ILRComponentMappingDoc"
import ILRSiteDoc from "./Components/Pages/Documentation/GEMS/ILRSiteDoc"
import ILRReprocess from "./Components/Pages/Documentation/GEMS/ILRReprocess";
import GOMLanding from "./Components/Pages/Documentation/GOM/GOMLanding"
import GOMSiteDoc from "./Components/Pages/Documentation/GOM/GOMSiteDoc"
import ComponentGroupLookupDoc from "./Components/Pages/Documentation/GOM/ComponentGroupLookupDoc"
import ComponentMappingDoc from "./Components/Pages/Documentation/GOM/ComponentMappingDoc"
import TranslationDoc from "./Components/Pages/Documentation/GOM/TranslationDoc"
import GOMSampleFind from "./Components/Pages/Documentation/GOM/GOMSampleFind"
import SupportLanding from "./Components/Pages/Documentation/Support/SupportLanding"
import TranslationGrid from './Components/Pages/Translation/TranslationGrid';
import Architecture from "./Components/Pages/Documentation/Support/Architecture";
import IlrProcess from "./Components/Pages/Documentation/Support/IlrProcess";
import IlrStatus from "./Components/Pages/Documentation/Support/IlrStatus";
import GomProcess from "./Components/Pages/Documentation/Support/GomProcess";
import Resources from "./Components/Pages/Documentation/Support/Resources";
import GomRequestGrid from './Components/Pages/GOMTestFramework/GomRequestGrid';
import { SiteAccessProvider } from "./contexts/SiteAccessContext";

const App = () => {

  const account = publicClientApplication.getAllAccounts()[0];
  const { contributor, admin } = usePrivileges();

  return (
      <>
          <AlertProvider>
              <NotificationAlert></NotificationAlert>
              <SiteAccessProvider>
                  <NavBar />
                  <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route exact path="/LogDetails" element={<LogDetails />} />
                      <Route exact path="/ComponentGroupLookup" element={<ComponentGroupLookupGrid />} />
                      <Route exact path="/Site" element={<SiteGrid />} />
                      <Route exact path="/ComponentMapping" element={<ComponentMappingGrid />} />
                      <Route exact path="/SiteDetails" element={<SiteGrid />} />
                      <Route exact path="/ILRSiteDetails" element={<ILRSiteGrid />} />
                      <Route exact path="/CofAConfiguration" element={<CofAConfiguration />} />
                      <Route exact path="/CofQConfiguration" element={<CofQConfiguration />} />
                      <Route exact path="/ILRComponentMapping" element={<ILRComponentMappingGrid />} />
                      <Route exact path="/ILRInspectionLotRequest" element={<ILRInspectionLotRequestGrid />} />
                      <Route exact path="/ILRMaterialMap" element={<ILRMaterialMapGrid />} />
                      <Route exact path="/DocLand" element={<DocLand />} />
                      <Route exact path="/SampleFind" element={<SampleFind />} />
                      <Route exact path="/GEMSLanding" element={<GEMSLanding />} />
                      <Route exact path="/ILRMaterialMapDoc" element={<ILRMaterialMapDoc />} />
                      <Route exact path="/ILRComponentMappingDoc" element={<ILRComponentMappingDoc />} />
                      <Route exact path="/ILRSiteDoc" element={<ILRSiteDoc />} />
                      <Route exact path="/ILRReprocess" element={<ILRReprocess />} />
                      <Route exact path="/GOMLanding" element={<GOMLanding />} />
                      <Route exact path="/GOMSiteDoc" element={<GOMSiteDoc />} />
                      <Route exact path="/ComponentGroupLookupDoc" element={<ComponentGroupLookupDoc />} />
                      <Route exact path="/ComponentMappingDoc" element={<ComponentMappingDoc />} />
                      <Route exact path="/TranslationDoc" element={<TranslationDoc />} />
                      <Route exact path="/GOMSampleFind" element={<GOMSampleFind />} />
                      <Route exact path="/SupportLanding" element={<SupportLanding />} />
                      <Route exact path="/Translation" element={<TranslationGrid />} />
                      <Route exact path="/GomRequestGrid" element={<GomRequestGrid />} />
                      <Route exact path="/Architecture" element={<Architecture />} />
                      <Route exact path="/IlrProcess" element={<IlrProcess />} />
                      <Route exact path="/IlrStatus" element={<IlrStatus />} />
                      <Route exact path="/GomProcess" element={<GomProcess />} />
                      <Route exact path="/Resources" element={<Resources />} />
                  </Routes>
                  <Footer />
              </SiteAccessProvider>
          </AlertProvider>
    </>
  );
}

export default App;
