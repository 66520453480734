import React, { useState, useEffect, useContext } from 'react'

import SiteAccessContext from '../../../contexts/SiteAccessContext';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';
import { usePrivileges } from '../../../hooks/usePrivileges';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';
import gridLogo from "../../../Images/grid.svg";
import SiteForm from './SiteForm';

// Styling
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

function SiteGrid(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [pageSize, setPageSize] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [Alertopen, AlertsetOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');

    const { assignedSites } = useContext(SiteAccessContext);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const { admin } = usePrivileges();

    const { setAlert } = useAlert();

    const getSiteDetailsUsingBearerToken = async function () {

        let params = new URLSearchParams();

        if (assignedSites && assignedSites.length > 0) {

            assignedSites.map(si => params.append("sites", si.siteName));

            await fetchDataAuthenticated(_apiUrl + "SiteDetails?" + params.toString())
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then((data) => {
                    setTableData(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", error.message);
                    console.error(error);
                });
        }
        else {
            console.error("In getSiteDetailsUsingBearerToken - You have no access to sites");
        }
    };

    const DeleteRow = (params) => {
        return (
            <strong>
                <button type="button" style={{ width: "80%" }} className="btn btn-outline-primary btn-sm"
                disabled={params.row.active ? true : false}
                onClick={() => {
                    ActivateOrInactivateSite(params.row, true);
                }}>Active</button>

                <button type="button" style={{ width: "80%" }} className="btn btn-outline-danger btn-sm" 
                disabled={params.row.active ? false : true}
                onClick={() => {
                    ActivateOrInactivateSite(params.row, false);
                }}>Inactive</button>
            </strong>
        )
    }

    const EditRow = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openEditSite(params.row);
                }}>Edit</button>
            </strong>
        )
    }

    var _column = [
        {
            field: 'sapSiteCode',
            headerName: 'SAP Site Code',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'siteName',
            headerName: 'Site',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'thirdPartyLabId',
            headerName: 'Third Party Lab Id',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'thirdPartyLabComment',
            headerName: 'Third Party Lab Comment',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'periodicAnalysis',
            headerName: 'Periodic Analysis',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        , {
            field: 'statusCoqOkValue',
            headerName: 'Coq Ok Value',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        }
        , {
            field: 'sampleType',
            headerName: 'Sample Type',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'smapiConnection',
            headerName: 'SM API Connection',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'active',
            headerName: 'Active',
            width: 80,
            headerAlign: 'center',
            align: 'center'
        },
        , {
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'modifiedDate',
            headerName: 'ModifiedDate',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    // TODO: What roles should this column be visible for?
    // props.PostData.roleId != 2
    if (admin) {
        _column.push(
            {
                field: '-',
                headerName: 'Action (Edit)',
                width: 100,
                renderCell: EditRow,
                disableClickEventBubbling: true,
                headerAlign: 'center',
                align: 'center'
            });
    }

    if (admin) {
        _column.push({
            field: '/',
            headerName: 'Action (Active/Inactive)',
            width: 220,
            renderCell: DeleteRow,
            disableClickEventBubbling: true,
            headerAlign: 'center',
            align: 'left'
        });
    }

    var columns: GridColDef[] = _column;


    const openEditSite = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openAddSite = (params) => {
        setOpen(true);
        setSelectedValue("");
    };

    const handleFormClose = (response) => {
        if (response != null && response == true) {
            getSiteDetailsUsingBearerToken();
        }
        setOpen(false);
    };

    const handleAlertClose = (response) => {
        AlertsetOpen(false);
    };

    const ActivateOrInactivateSite = async (rowData, isActive) => {
        const SiteMapFields = {
            SiteId: rowData.siteId,
            Active: isActive,
            ModifiedBy: username
        };

        await fetchDataAuthenticated(_apiUrl + 'ActiveInactiveSite', "POST", JSON.stringify(SiteMapFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                getSiteDetailsUsingBearerToken();
                AlertsetOpen(false);
            })
            .catch(error => {
                AlertsetOpen(false);
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    useEffect(() => {
        getSiteDetailsUsingBearerToken();
    }, [assignedSites]);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-9 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    Site
                </div>
                <div className='col-md-3'>
                    {/*TODO: implement this correctly: props.PostData.roleId != 2*/}
                    {admin && <button type="button" className="btn btn-outline-primary btn-primary-label" onClick={openAddSite}>Add Site</button>}
                </div>
            </div>

            <Box>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.siteId + row.siteName}
                />
            </Box>

            <Dialog open={open} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">Site</DialogTitle>
                <SiteForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>

            <Dialog
                open={Alertopen}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this item?
                    </DialogContentText>
                </DialogContent>
                <div className='container-fluid row mt-3 mb-3 '>
                    <div className='col-md-3'></div>
                    <div className='col-md-9 row'>
                        <div className='col-md-6'>
                            <button type="button" className="btn btn-outline-secondary  btn-sm" onClick={handleAlertClose}>Cancel</button>
                        </div>
                        <div className='col-md-6'>
                            <button type="button" className="btn btn-outline-danger  btn-sm" onClick={ActivateOrInactivateSite}>Delete</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
export default SiteGrid;