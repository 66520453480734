import React, { useState, useEffect, useRef } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

// Styling
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

function TranslationForm(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [siteDetails, setSiteDetails] = useState([]);

    const defaultRow = {
        translationId: 0,
        site: "",
        language: "",
        translationType: "",
        limsText: "",
        cofQText: "",
        description: "",
        modifiedBy: ""
    };

    const { setAlert } = useAlert();

    const AddTranslationflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;
    const [site, setSite] = React.useState(SelectedValue.site);
    const [TranslationValue, SelectedTranslationValue] = React.useState(SelectedValue);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };
    const [translationType, setTranslationType] = React.useState(SelectedValue.translationType);
    const handleChangeTranslationType = (event) => {
        setTranslationType(event.target.value);
        TranslationValue.translationType = event.target.value;
        SelectedTranslationValue(TranslationValue);
    };
    const handleTextFieldChange = (event) => {
        TranslationValue[event.target.id] = event.target.value;
        SelectedTranslationValue(TranslationValue);
    };
    const handleChangeSite = (event) => {
        setSite(event.target.value);
        TranslationValue.site = event.target.value;
        SelectedTranslationValue(TranslationValue);
    };

    const saveTranslationValues = async () => {
        const saveBtn = document.getElementById('btnSave');
        const serviceUrl = _apiUrl + (AddTranslationflag ? "AddTranslationDetails" : "UpdateTranslationDetails")

            if (TranslationValue.site == null || TranslationValue.site === undefined || TranslationValue.site === "") {
                setAlert("primary", "Validation", "Please select Site");
                return false;
            }
            if (TranslationValue.language == null || TranslationValue.language === undefined || TranslationValue.language === "") {
                setAlert("primary", "Validation", "Please enter Language");
                return false;
            }
            if (TranslationValue.translationType == null || TranslationValue.translationType === undefined || TranslationValue.translationType === "") {
                setAlert("primary", "Validation", "Please enter Translation Type");
                return false;
            }
            if (TranslationValue.limsText == null || TranslationValue.limsText === undefined || TranslationValue.limsText === "") {
                setAlert("primary", "Validation", "Please enter Sample Manager Text");
                return false;
            }
            if (TranslationValue.cofQText == null || TranslationValue.cofQText === undefined || TranslationValue.cofQText === "") {
                setAlert("primary", "Validation", "Please enter CofQ Text");
                return false;
            }
        TranslationValue.modifiedBy = username;
        saveBtn.disabled = true;
        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(TranslationValue))
            .then(response => {
                if (response.ok) {
                    saveBtn.disabled = false;
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
                saveBtn.disabled = false;
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
                saveBtn.disabled = false;
            });
    }

    const LoadSiteDetails = async () => {

        await fetchDataAuthenticated(_apiUrl + "UniqueGomSiteDetails")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setSiteDetails(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });
    }

    useEffect(() => {
        LoadSiteDetails();
    }, []);

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="lblsiteId" className='required'>Site</InputLabel>
                                <Select
                                    labelId="lblSiteId"
                                    id="isiteId"
                                    value={site}
                                    label="Site *"
                                    onChange={handleChangeSite}
                                >
                                    <MenuItem value="">Select Site</MenuItem>
                                    {siteDetails.map((sitedt, index) => {
                                        return (
                                            <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                                        );
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="language"
                                label="Language"
                                defaultValue={TranslationValue.language}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 2 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="lblTranslationType" className='required'>Translation Type</InputLabel>
                                <Select
                                    labelId="lblTranslationType"
                                    id="ilblTranslationType"
                                    value={translationType}
                                    onChange={handleChangeTranslationType}
                                    label="TranslationType *"
                                >
                                    <MenuItem value={'Specifications'}>Specifications</MenuItem>
                                    <MenuItem value={'Method'}>Method</MenuItem>
                                    <MenuItem value={'Result'}>Result</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="limsText"
                                label="Lims Text"
                                defaultValue={TranslationValue.limsText}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="cofQText"
                                label="CofQ Text"
                                defaultValue={TranslationValue.cofQText}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="description"
                                label="Description"
                                defaultValue={TranslationValue.description}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='clearfix'></div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-9'></div>
                <div className='col-md-3 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" id="btnSave" className="btn btn-outline-primary" onClick={saveTranslationValues}>Save</button>
                    </div>
                </div>
            </div>
        </DialogContent>

    );
}
export default TranslationForm;