import React, { useState, useEffect } from "react";
import Burger from "../Images/menu-burger.svg";
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GOMMappingSubMenu from "./SubMenuBars/GOMMappingSubMenu";
import GEMSMappingSubMenu from "../Components/SubMenuBars/GEMSMappingSubMenu";
import { useMsal } from '@azure/msal-react';
import { Profile } from '../Components/SubMenuBars/Profile';
import { useListPrivileges } from '../hooks/usePrivileges';

function NavBar(props) {

    const [open, setOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);

    const { accounts } = useMsal();

    const name = accounts[0] && accounts[0].name;
    const username = accounts[0] && accounts[0].username;
    const { privileges, loading } = useListPrivileges();

    return (
        <div>
            <nav>
                <div className="logo"><Link to='/' className='nav-link'>Certificate of Quality</Link></div>
                <ul className="nav-links" style={{ transform: open ? "translateX(0px)" : "" }}>
                    <li><Link to='/' className='nav-link'>Home</Link></li>
                    <li onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
                        <Link className='nav-link'>GOM</Link>
                        {dropdown && <GOMMappingSubMenu />}
                    </li>
                    <li onMouseEnter={() => setDropdown2(true)} onMouseLeave={() => setDropdown2(false)}>
                        <Link className='nav-link'>GEMS</Link>
                        {dropdown2 && <GEMSMappingSubMenu />}
                    </li>
                    <li><Link to='/LogDetails' className='nav-link'>Logs</Link></li>
                </ul>
                <div style={{ cursor: "pointer" }} onMouseEnter={() => setDropdown1(true)} onMouseLeave={() => setDropdown1(false)}>
                    <span>
                        {name ? name : 'CoQ'}
                    </span>
                    {dropdown1 && username != null && <Profile username={username} privileges={privileges} />}
                </div>
                <img src={Burger} onClick={() => setOpen(!open)} className="burger" />
            </nav>
        </div>
    )
}

export default NavBar;